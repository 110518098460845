<template>
  <div>
    <b-table :mobile-cards="false" :data="data" bordered>
      <template #empty>
        <p class="has-text-centered">{{ $t('no') }} Data</p>
      </template>

      <b-table-column field="pond" :label="$t('farm.pond')" v-slot="props">
        {{ props.row.pond_name }}
      </b-table-column>
      <b-table-column field="created_at" :label="$t('date')" v-slot="props">
        {{ $dateFormat(props.row.created_at) }}
      </b-table-column>
      <b-table-column field="doc" label="DOC" v-slot="props">
        {{ props.row.stock ? $getDOC(props.row.stock.stock_date, props.row.created_at) : '-' }}
      </b-table-column>
      <b-table-column field="amount" :label="$t('water_change.total')" v-slot="props">
        {{ $number(props.row.value) }} cm
      </b-table-column>

      <b-table-column field="action" label="" v-slot="props" v-if="canEditWaterQuality">
        <b-button class="mr-2" type="is-secondary is-light" @click="editWater(props.row)"
                  icon-left="pencil-outline"></b-button>
        <b-button type="is-danger is-light" @click="deleteWater(props.row.water_change_id)"
                  icon-left="trash-can-outline"></b-button>
      </b-table-column>
    </b-table>

    <!--  Modal Water Change -->

    <b-modal
        v-model="isModalOpen"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <input-water-change is-modal @close="props.close" @submit="getData(true)" :default-value="water_change_form"/>
      </template>
    </b-modal>

  </div>
</template>

<script>
import InputWaterChange from "@/views/Farm/WaterChange/InputWaterChange";
export default {
  name: "WaterChangeOverview",
  components: {
    InputWaterChange
  },
  data: () => {
    return {
      data: [],
      water_change_form: null,
      isModalOpen: false,
    }
  },
  computed: {
    canEditWaterQuality() {
      return this.$store.getters["farm/canEditWaterQuality"]
    },
    ponds() {
      return this.$store.state.farm.overview.ponds
    },
    refresh_data() {
      return this.$store.state.farm.overview.refresh_data
    }
  },
  watch: {
    refresh_data(value) {
      if (value) this.getData(true)
    },
  },
  created() {
    this.getData(true)
  },
  methods: {
    async getData(refresh = false) {
      this.$loading()
      this.data = []

      let stock_ids = []

      for (let i = 0; i < this.ponds.length; i++) {
        let pond = this.ponds[i]

        if (!pond.stock) {
          pond.stock = await this.$store.dispatch('farm/getStockOverview', pond)
        }

        stock_ids = stock_ids.concat(pond.stock.map(e => e.stock_id))

        if (pond.selected_cycle) {
          if (refresh || !pond.water_change) {
            pond.water_change = []

            let stock = pond.stock.find(e => e.cycle_id === pond.selected_cycle.cycle_id)

            let water_change = await this.$store.dispatch('farm/getWaterChange', pond.selected_cycle.cycle_id)

            if (!water_change.error && water_change.data && Array.isArray(water_change.data)) {
              pond.water_change = water_change.data.map(e => {
                e.pond_id = pond.pond_id
                e.pond_name = pond.name
                e.block_id = pond.block_id
                e.block_name = pond.block_name
                e.stock = stock
                return e
              })
            }
          }
        }
      }

      this.data = this.ponds.reduce((a, b) => {
        if (b.water_change) a = a.concat(b.water_change)
        return a
      }, []).sort((a, b) => {
        if (a.pond_name !== b.pond_name) {
          return a.pond_name.localeCompare(b.pond_name, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        }
        return b.created_at > a.created_at ? 1 : -1
      })
      this.$loading(false)
      this.$store.dispatch('farm/refreshOverview', false)
    },

    editWater(water_change) {
      this.water_change_form = this._.cloneDeep(water_change)
      this.isModalOpen = true
    },
    deleteWater(id) {
      this.$delete(async () => {
        this.$loading()
        await this.$store.dispatch('farm/deleteWaterChange', id)
        await this.getData(true)
        this.$loading(false)
      })
    },
  }
}
</script>
